var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-navigation-drawer',{staticClass:"grey lighten-3",staticStyle:{"z-index":"1054"},attrs:{"clipped":"","stateless":_vm.drawerStateless,"fixed":"","temporary":"","right":"","width":_vm.rightDrawerWidth},model:{value:(_vm.drawerState),callback:function ($$v) {_vm.drawerState=$$v},expression:"drawerState"}},[_c('v-toolbar',{staticClass:"elevation-1 ",attrs:{"dark":"","dense":"","color":(_vm.element.state == 'modifier') ? 'red darken-4' : 'primary'}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-width":600,"offset-y":"","z-index":"1100"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
return [_c('v-tooltip',{attrs:{"right":"","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"slot":"activator","icon":""},slot:"activator"},Object.assign({}, tooltip, menu)),[_c('v-badge',{staticClass:"mt-1",attrs:{"color":"red","content":_vm.bookmarkCount,"value":_vm.bookmarkCount}},[_c('v-icon',[_vm._v("mdi-card-bulleted-outline")])],1)],1)]}}],null,true)},[_c('span',[_vm._v("Voir les fiches en favoris")])])]}}]),model:{value:(_vm.sliderMenu),callback:function ($$v) {_vm.sliderMenu=$$v},expression:"sliderMenu"}}),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ma-1",attrs:{"x-small":"","fab":"","depressed":"","color":(_vm.bookmark['b-' + _vm.objCard.cad_uid]) ? 'amber darken-1' : 'grey darken-1'},on:{"click":function($event){return _vm.addToBookmark()}}},on),[_c('v-icon',[_vm._v("mdi-star")])],1)]}}])},[_c('span',[_vm._v("Ajout favoris")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ma-1",attrs:{"x-small":"","fab":"","depressed":"","color":"light-blue darken-1"},on:{"click":function($event){return _vm.leftDrawerMaximize()}}},on),[_c('v-icon',[_vm._v("mdi-window-maximize")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.labels.windowSize))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ma-1",attrs:{"x-small":"","fab":"","depressed":"","color":"red darken-1","href":_vm.link.base}},on),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}])},[_c('span',[_vm._v("Fermer")])])],1),(!_vm.openRoute)?_c('v-progress-linear',{attrs:{"indeterminate":"","color":"blue","height":"5"}}):_vm._e(),(_vm.openRoute)?_c('router-view',{attrs:{"name":"content","toast":_vm.toast,"link":_vm.link,"routeState":_vm.element,"objCard":_vm.objCard}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }