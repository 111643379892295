<template>
<v-container fluid :class="{ 'blue': dragover }">
    <v-toolbar class="elevation-0" height="48px">
        <v-toolbar-title >Liste des contrôles</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn v-if="enableAdd && isAuthorized( 'phyto', 'edit' )" 
            outlined rounded small :color="'green'" @click="openItem(0)">
            Ajouter<v-icon>mdi-playlist-edit</v-icon>
        </v-btn>
    </v-toolbar>
    <v-data-table v-bind:headers="headers" :items="items" item-key="sta_id"
        items-per-page-text="Lignes par page" :items-per-page-options="pagination.rowsPerItem" :loading="loading.getItem"> 
        <template v-slot:[`item.phy_date`]="{ item }">
            <v-chip label outlined >{{ $date(item.phy_date).format('DD/MM/YYYY') }}</v-chip>
        </template>
        <template v-slot:[`item.phy_title`]="{ item }">
            <span class="d-inline-block text-truncate" style="max-width: 400px;">
            {{ item.phy_title }}
            </span>
        </template>
        <template v-slot:[`item.phy_id`]="{ item }">
            <v-icon left color='orange' title="Editer" v-if="isAuthorized( 'phyto', 'edit' )" @click="editItem(item.phy_id)">
                mdi-pencil
            </v-icon>
            <v-icon left color='red' title="Supprimer" v-if="isAuthorized( 'phyto', 'delete' )" @click="deleteItem(item.phy_id)">
                mdi-delete-forever
            </v-icon>
        </template>
        <template slot="pageText" slot-scope="{ pageStart, pageStop }">
        De {{ pageStart }} à {{ pageStop }}
        </template>
        <template slot="no-data">
        <v-alert :value="( !loading.getItem )" color="error" icon="mdi-alert">
            Aucune fichier, ajoutez un nouveau fichier
        </v-alert>
        </template>
    </v-data-table>

    <v-dialog v-model="modalLayer" max-width="900" >
        <!-- <tab-layer v-if="modalLayer" :doc_id="doc_id" :toast="toast" :switchModal="switchModal"></tab-layer> -->
    </v-dialog>
    <v-dialog v-model="modalItem" max-width="900" >
        <v-card color="grey lighten-4" min-width="350px" flat >
        <v-toolbar color="primary" dark class="elevation-1" height="50px">
            <v-btn icon><v-icon>mdi-chevron-down</v-icon></v-btn>
            <v-toolbar-title>Phyto</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon color="" @click.native="switchModal('modalItem', 0)"><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text class="mt-2">
            <PhytoForm class="elevation-2" 
                v-if="modalItem" :toast="toast" :switchModal="switchModal"
                :cad_id="xxx_id" :itemId="itemId">
            </PhytoForm>
        </v-card-text>
        </v-card>
    </v-dialog>
</v-container>
</template>

<script>
import PhytoForm from '@/components/phyto/PhytoForm.vue'
import { usrGetters }   from "@/store/user.module"

export default {
    name: 'tab_phyto',
    props: ['toast', 'table_id', 'xxx_id', 'routeState', 'linkedId'],
    components: { PhytoForm },
    data: function () {
      return {
            loading: { uploadBtn: false, getItem: false },         // Enregistrement en cours...
            dragover: false,
            uploadedFiles: undefined,
            pagination: { totalItems: 0, rowsPerPage: 10, rowsPerItem: [15,25,35,{text:'Tout',value:-1}] },
            headers: [
            { text: 'Nom', align: 'left', value: 'phy_title'},
            { text: 'Date de création',   value: 'phy_date', align: 'left'},
            { text: 'Actions', value: 'phy_id', align: 'right' }
            ],
            items: [],
            enableAdd: true,
            modalLayer : false,
            item: { doc_id: 0, doc_sta_id : 'DOC01', doc_table_id: this.table_id, doc_xxx_id  : this.xxx_id },
            modalItem: false,
            isAuthorized: usrGetters.getScopeRight
      }
    },
    mounted: function (){
        this.getItems()
    },
    methods: {
        getItems(){
            //this.items = []
            this.loading.getItem = true
            var searchCriteria = '?per_page=false' //page='+ this.pagination.page +'&
            searchCriteria    += '&phy_cad_id=' +  this.xxx_id
            this.$http.get( '/phyto/' + searchCriteria ).then( (response) => {
                if( response.data.meta.pagination.total !== undefined && response.data.meta.pagination.total !== '0' ){
                    this.pagination.totalItems  = parseInt(response.data.meta.pagination.total)
                } else {
                    this.pagination.totalItems = 1
                }
                if( Object.prototype.toString.call( response.data.data ) === '[object Array]' ) {
                    this.items = response.data.data
                }
                this.loading.getItem = false
            })
        },
        openItem(id){
            this.itemId = id
            this.switchModal('modalItem', 1)
        },
        editItem(id){
            this.itemId = id
            this.switchModal('modalItem', 1)
        },
        openAssocModal(id){
            if( id !== undefined && id > 0 ){
                this.switchModal('modalLayer', 1)
            }
        },
        deleteItem( id ){
            this.itemId = id
            this.$root.$confirm('Supprimer', 'Confirmer la suppression de ce controle', { color: 'red', width: 400 }).then((confirm) => {
                if( confirm ){
                    this.$http.delete( 'phyto/' +  this.itemId).then( () => {
                        this.toast({ color: 'error', top:false, bottom:true, right:false, left:true, text:'Controle supprimée !' })
                        this.getItems()
                    })
                }
            })
        },
        switchModal(name, mode, refresh){
            name    = name || 'modalItem'
            mode    = mode || 0
            refresh = refresh || false
            this[name] = mode
            if( mode == 0 ){
                this.uploadedFiles = undefined
                //this.xxx_id = 0
            }
            if( refresh ){
                this.getItems()
            }
        }
    }
}
</script>