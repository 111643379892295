<template>
<v-container fluid class="pa-0">
    <v-toolbar class="elevation-0" color="grey lighten-4" height="48px">
        <v-toolbar-title class="body-2 grey--text">{{ layerName }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn small rounded outlined depressed text color="primary" class="mr-2 white--text" @click="downloadPdf()">
            <v-icon dark>mdi-cloud-download-outline</v-icon><span class="hidden-sm-and-down"></span>
        </v-btn>
        <!-- <v-chip v-show="true" small color="blue lighten-1" text-color="white">{{ 'area' }} m2</v-chip>
        <v-chip v-show="true" small color="amber lighten-1" text-color="white">{{ 'size' }} m</v-chip>
        <v-chip small color="grey" text-color="white">#{{ crd_acad_id }}</v-chip> -->
    </v-toolbar>
    <v-divider></v-divider>
    <v-container fluid class="mt-2 pa-2">
    <v-card v-if="formData" class="pb-3 elevation-10" style="background-color: #24402f; border-bottom-left-radius: 40px;" dark>
        <v-card-text class="pr-0">
        <v-layout row wrap class="ma-0 mt-2">
            <v-flex xs7 class="pb-0" align-start>
                <v-expansion-panels light v-model="typPanel">
                <v-expansion-panel expand v-model="typPanel"
                    v-for="(itemType, itemIndex) in typInfo" :key="itemIndex" :style="'background-color: ' + itemType.typ_color + '; border-bottom-left-radius: 40px;'">
                    <v-expansion-panel-header  :style="'min-height: 40px !important; background-color: ' + itemType.typ_color + ';'" class="pt-2 pb-2">{{ itemType.typ_name }}</v-expansion-panel-header>
                    <v-expansion-panel-content :style="'background-color: ' + itemType.typ_color + '; border-bottom-left-radius: 40px;'" class="pa-0 elevation-0">
                        <v-card class="gen-beauvais" style="background-color: #24402f; border-bottom-left-radius: 40px;" dark>
                            <template v-for="(item, index) in formItem">
                                <v-flex v-if="(item.ctp_typ_id_section == itemType.typ_id) && item.ctp_data_type == 'VARCHAR'" xs12 class="pr-2 pl-2" :key="index">
                                    <v-text-field :label="item.ctp_label" v-model="item.cdd_value" ></v-text-field>
                                </v-flex>
                                <v-flex v-else-if="(item.ctp_typ_id_section == itemType.typ_id) && item.ctp_data_type == 'INT'" xs12 class="pr-2 pl-2" :key="index">
                                    <v-text-field :label="item.ctp_label" v-model="item.cdd_value" type="number"></v-text-field>
                                </v-flex>
                                <v-flex v-else-if="(item.ctp_typ_id_section == itemType.typ_id) && item.ctp_data_type == 'FLOAT'" xs12 class="pr-2 pl-2" :key="index">
                                    <v-text-field :label="item.ctp_label" v-model="item.cdd_value" type="number"></v-text-field>
                                </v-flex>
                                <v-flex v-else-if="(item.ctp_typ_id_section == itemType.typ_id) && item.ctp_data_type == 'BOOL'" xs12 class="pr-2 pl-2" :key="index">
                                    <v-checkbox :label="item.ctp_label" v-model="item.cdd_value" ></v-checkbox>
                                </v-flex>
                                <v-flex v-else-if="(item.ctp_typ_id_section == itemType.typ_id) && item.ctp_data_type == 'LIST'" xs12 class="pr-2 pl-2" :key="index">
                                    <v-select style="z-index: 2000;"
                                        :items="listsData[item.ctp_lst_id]" item-value="lsd_id" item-text="lsd_label"
                                        v-model="item.cdd_lsd_id" :label="item.ctp_label" @change="listChange(item, index)">
                                    </v-select> <!-- single-line -->
                                </v-flex>
                                <v-flex v-else-if="(item.ctp_typ_id_section == itemType.typ_id) && item.ctp_data_type == 'PERCENT'" xs12 class="pr-2 pl-2" :key="index">
                                    <v-text-field :label="item.ctp_label" v-model="item.cdd_value" ></v-text-field>
                                </v-flex>
                            </template>
                        </v-card>
                    </v-expansion-panel-content>
                </v-expansion-panel>
                </v-expansion-panels>
            </v-flex>
            <v-flex xs5 class="d-flex align-start">
                <!-- <v-img v-if="(treePic.length > 100)" :src="treePic" aspect-ratio class="ma-3 elevation-9" style="border: 3px solid #FFF !important; "></v-img>
                <v-img v-else src="../assets/arbre_gen.jpg" aspect-ratio class="ma-3 elevation-9" style="border: 3px solid #FFF !important; "></v-img> -->
                <v-icon v-show="((!uploadedImage && !resizeImage && !objCard.cad_doc_id_image) || !itemsCarousel.length)" size="10em" @click="$refs.inputUpload.click()" class="ma-3 elevation-9">mdi-camera</v-icon>
                <img ref="imgSrc" :src="uploadedImageSrc" v-show="false" class="ma-3 elevation-9" />
                <!-- <v-img ref="resizedImage" max-width="100%" max-height="20em" style="cursor:pointer;" 
                        v-show="(uploadedImage || objCard.cad_doc_id_image)" :src="resizedImage" aspect-ratio="1" @click="$refs.inputUpload.click()" class="ma-3 elevation-3 rounded-lg">
                </v-img> 
                <v-progress-circular v-if="resizeImage" :size="100" :width="5" color="primary" indeterminate></v-progress-circular>-->
                <input ref="inputUpload" v-show="false" accept="image/*" type="file" @change="handleImageImport()" />

                <v-carousel v-show="( (uploadedImage || objCard.cad_doc_id_image) && itemsCarousel.length )" ref="resizedImage" class="ml-2 mr-2 elevation-3 rounded-lg">
                    <v-carousel-item
                        v-for="(item,i) in itemsCarousel" :key="i" :src="item.src"
                        reverse-transition="fade-transition" transition="fade-transition" @click="$refs.inputUpload.click()"
                        style="cursor:pointer;">
                    </v-carousel-item>
                </v-carousel>
            </v-flex>
        </v-layout>
        </v-card-text>
    </v-card>
    <v-toolbar extended flat color="transparent" class="elevation-0" >
        <v-btn color="red"  class="btn-beauvais elevation-9" dark @click.native="deleteTree()" :title="'Supprimer'">
            <v-icon left>mdi-trash-can</v-icon>
        </v-btn>
        <v-btn color="brown" class="ma-0 ml-2 btn-beauvais elevation-9" dark @click.native="archiveItem()" :title="'Archiver'">
            <v-icon left>mdi-archive-arrow-down-outline</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="#73191c"  class="mr-3 btn-beauvais elevation-9" dark :loading="saveProgress" @click.native="saveForm()" :disabled="saveProgress">Enregistrer</v-btn>
        <v-btn color="#595959"  class="btn-beauvais elevation-9" dark :href="link.base">Retour</v-btn>
    </v-toolbar>
    <v-alert v-if="!formData" :value="true" color="warning" icon="mdi-alert">
        Aucun formulaire n'est disponible pour ce type d'objet
    </v-alert>
    <!-- Associative Tab -->
    <v-card fluid class="mt-4 pa-0 elevation-0" v-if="objCard.cad_id && objCard.cad_typ_id_shape == 'SHA02'">
        <v-divider></v-divider>
        <v-tabs v-model="activeTab" grow light show-arrows="mobile">
            <v-tabs-slider color="green"></v-tabs-slider>
            <v-tab v-for="item in tabItems" :key="item" classs="gen-beauvais" style="border-bottom-left-radius: 20px !important; background-color:white;">{{ item }}</v-tab>
        </v-tabs>
        <v-divider></v-divider>
        <EventTab    v-if="activeTab === 0 && showTab == true" :routeState="routeState" :xxx_id="objCard.cad_id" :table_id="'cad'" :toast="toast" typfilter="ACT" title="Taches"></EventTab>
        <DocumentTab v-if="activeTab === 1 && showTab == true" :routeState="routeState" :xxx_id="objCard.cad_id" :table_id="'cad'" :toast="toast" :linkedId="objCard.cad_doc_id_image" @linkDoc="linkedDocument"></DocumentTab>
        <EventTab   v-if="activeTab === 2 && showTab == true" :routeState="routeState" :xxx_id="objCard.cad_id" :table_id="'cad'" :toast="toast" :typfilter="'ALT'" title="Alerte"></EventTab>
        <PhytoTab   v-if="activeTab === 3 && showTab == true" :routeState="routeState" :xxx_id="objCard.cad_id" :table_id="'cad'" :toast="toast" :typfilter="'ALT'" title="Alerte"></PhytoTab>
    </v-card> 
    </v-container>
</v-container>
</template>

<script>
import dateLocale  from '@/services/dateLocale';
import EventTab    from '@/components/event/Tab_event.vue'
import DocumentTab from '@/components/Tab_document.vue'
import PhytoTab    from '@/components/phyto/PhytoList.vue'

import { cacheGetters } from "../store/cache.module"
import { cadObj }       from '../mixins/cad_obj.js'
import { docObj }       from '../mixins/doc_obj.js'
import { treeUpdateService } from '@/rxjsServices';
import Pica from "pica"

export default {
    name: 'object_edit',
    props: ['dxfObject', 'switchModal', 'toast', 'routeState', 'link'],
    mixins: [ cadObj, docObj ],
    components: { EventTab, DocumentTab, PhytoTab },
    data: function () {
        return {
            saveProgress: false,         // Enregistrement en cours...
            formData    : false,
            locDateFormat: dateLocale, // Format de date
            obtObj: { _method:'POST', _path:'objecttype/' },
            obtObjClone:'',
            formItem: [],
            pro_id: 0,
            crd_acad_id: 0, //parseInt(this.dxfObject['handle'], 16),
            activeTab: -1, showTab: true,
            tabItems: [ 'Taches / Evenements', 'Fichiers', 'Alertes', 'Phyto' ],
            primaryItem: 30,
            secondaryItem: 4,
            treePic: "",
            listsData: {},
            allDataList: [],
            layerName: '',
            typPanel: 0,
            typInfo: [],
            uploadedImage: false,
            resizeImage: false,
            resizedImage: '',
            uploadedImageSrc: '',
            itemsCarousel: [],
            keyCarousel : 0
      }
    },
    beforeMount: function(){
        this.typInfo = []
        cacheGetters.getTypes('CTP').forEach( (item) => {
            if( item.typ_visibility ){
                this.typInfo.push(item)
            }
        })
    },
    mounted: function (){
        this.listsData   = this.getListsDataByLstId()
        this.allDataList = cacheGetters.getListsData()
        // Chargement des info
        setTimeout( () => {
            if( this.$route.params.cad_uid !== undefined && this.$route.params.cad_uid.length > 10 ){
                this.objCard.cad_uid = this.$route.params.cad_uid
                this.getForm()
                this.activeTab = 0
            }
            //this.getImage()
        }, 400) 
    },
    methods: {
        async getForm(){
            this.formData     = false
            await this.getCardShow()
            await this.getCarddata()
        },
        getCarddata(){
            return new Promise( (resolve, reject) => {
                // if card not exist get template
                if( !this.objCard.cad_id ){
                    this.objCard.cad_uid          = this.$route.params.cad_uid
                    this.objCard.cad_lra_id       = this.$route.params.lra_id
                    this.objCard.cad_typ_id_shape = this.$route.params.typ_id
                    let params  = '/layersassoc/?per_page=200&extended=withtemplate&order_by=ctp_pos_row&order_direction=asc';
                    params     += '&lra_id=' + this.objCard.cad_lra_id + '&ctp_typ_id=' + this.objCard.cad_typ_id_shape + ',SHA03'
                    this.$http.get( params )
                    .then( (response) => {
                        this.formItem = response.data.data
                        this.formData = true
                        this.formItem.forEach(element => { this.layerName = element.lra_source_id })
                        resolve('resolved')
                    }).catch( (error) => {
                        if(error.response && error.response.status === 404) {
                            resolve('resolved')
                        } else {
                            reject(error)
                        }
                    })
                } else {
                    let params  = '/layersassoc/?per_page=200&extended=withtemplate,withcarddata&order_by=ctp_pos_row&order_direction=asc';
                    params     += '&lra_id=' + this.objCard.cad_lra_id + '&ctp_typ_id=' + this.objCard.cad_typ_id_shape + ',SHA03'
                    params     += '&cad_id=' + this.objCard.cad_id
                    this.$http.get( params )
                    .then( (response) => {
                        this.formItem = response.data.data
                        this.formData = true
                        this.formItem.forEach(element => { this.layerName = element.lra_source_id })
                        if( this.objCard.cad_doc_id_image > 0 ){
                            this.getImage(this.objCard.cad_doc_id_image)
                            this.getCarouselImage()
                        }
                    }).catch( (error) => {
                        if(error.response && error.response.status === 404) {
                            resolve('resolved')
                        } else {
                            reject(error)
                        }
                    })
                }
            })
        },
        async saveForm(){
            let store = true
            if( !this.objCard.cad_id ){
                await this.postCardStore()
                this.formItem.forEach(element => {
                    element.cdd_cad_id = this.objCard.cad_id
                })
                this.objDocument.doc_xxx_id   = this.objCard.cad_id
                this.objDocument.doc_table_id = 'cad'
                this.objDocument.doc_typ_id   = 'DOC02'
                this.saveDocument().then( () => {
                    this.attachDocument(this.objDocument.doc_id)
                })
            } else {
                if( this.uploadedImage ){
                    this.objDocument.doc_xxx_id   = this.objCard.cad_id
                    this.objDocument.doc_table_id = 'cad'
                    this.objDocument.doc_typ_id   = 'DOC02'
                    this.saveDocument().then( () => {
                        this.attachDocument(this.objDocument.doc_id)
                    })
                }
                store = false
            }
            this.$http.post( '/cardsdata/fields', this.formItem ).then( () => {
                // reload if it's insert
                if( store ){
                    this.getCarddata()
                }
                this.toast({ text: 'Fiches enregistrée !' })
            })
            //this.$router.push({ path: this.link.base.replace('/#', '') })
        },
        archiveItem(){
            this.$root.$confirm('Archiver ', 'Confirmez vous cette action ?', { color: 'red', width: 400 }).then((confirm) => {
                if( confirm ){
                    this.$http.put( '/trees/' + this.objCard.cad_uid, { tre_sta_id: 'GEN04' } ).then( () => {
                        this.toast({ text: 'Action enregistrée !' })
                        this.$router.push({ path: this.link.base.replace('/#', '') })
                    })
                }
            })
        },
        cleanForm(){
            var obtClone = JSON.parse( this.obtObjClone )
            var prop
            for( prop in obtClone ){
                if( this.obtObj[prop] !== undefined ){
                    this.obtObj[prop] = obtClone[prop]
                }
            }
        },
//        listFilter(id){
//            let list = []
//            let listsData = this.getListsData()
//            listsData.forEach(element => {
//                console.log(element)
//                if( element.lsd_lst_id == id ){
//                    list.push( element )
//                }
//            })
//            return list
//        },
        listName(id){
            let name  = 'Liste'
            let lists = this.getLists()
            lists.forEach(element => {
                if( element.id == id ){
                    name = element.label
                    //return element.label
                }
            })
            return name
        },
        listChange(item, index){
            let listDataObj = this.listsData[item.ctp_lst_id].find(obj => obj.lsd_id === item.cdd_lsd_id)
            this.formItem[index].cdd_value = listDataObj.lsd_label
            if( listDataObj.lsd_lsd_id_link > 0 ){
                let lidDataLinked = this.allDataList.find(obj => obj.lsd_id === listDataObj.lsd_lsd_id_link)
                this.formItem.forEach(element => {
                    if( lidDataLinked && (element.ctp_lst_id === lidDataLinked.lsd_lst_id)){
                        element.cdd_lsd_id = lidDataLinked.lsd_id
                    }
                })
            }
        },
        downloadPdf(){
            this.saveProgress = true
            this.$http.customRequest({ method: 'get', url: 'cards/' + this.objCard.cad_uid + '/pdf', responseType: 'blob' } )
            .then( (response) => {
                let filename      = 'd.pdf'
                let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                let matches = filenameRegex.exec(response.headers['content-disposition']);
                if (matches != null && matches[1]) { 
                    filename = matches[1].replace(/['"]/g, '');
                }
                if( response.status == 204 ){
                    this.toast({ color: 'warning', text: 'Ce fichier n\'existe pas' })
                } else {
                    this.$download(response.data, filename, response.headers['content-type'])
                }
            }).catch( (error) => {
                this.toast({ color: 'error', text: error })
            })
        },
        linkedDocument(doc_id){
            this.getImage(doc_id)
        },
        async getCarouselImage(){
            this.itemsCarousel = []
            let results = []
            await this.$http.get('documents/?doc_table_id=cad&doc_xxx_id=' + this.objCard.cad_id + '&doc_typ_id=DOC02' ).then( (response) => {
                results = response.data.data
            })

            for( const pic in results ){
                results[pic].src = await this.getImage(results[pic].doc_id)
                if( results[pic].doc_id == this.objCard.cad_doc_id_image ){
                    this.itemsCarousel.unshift( results[pic] )
                } else {
                    this.itemsCarousel.push( results[pic] )
                }
            }
        },
        getImage(doc_id){
            return new Promise( (resolve, reject) => {
                this.$http.customRequest({ method: 'get', url: 'documents/' + doc_id + '/download', responseType: 'blob' } ).then( (response) => {
                    if( response.status == 204 ){
                        this.toast({ color: 'warning', text: 'Le fichier image n\'existe pas' })
                    } else {
                        var reader = new FileReader();
                        reader.readAsDataURL(response.data); 
                        reader.onloadend = () => {
                            var base64data = reader.result;                
                            this.resizedImage = base64data; //this.treePic = base64data;
                            resolve(base64data)
                        }
                    }
                }).catch( (error) => {
                    this.toast({ color: 'error', text: error })
                    reject( {error: error} )
                })
            })
        },
        handleImageImport(){
            this.resizeImage      = true
            const pica            = Pica();
            var files             = this.$refs.inputUpload.files
            this.uploadedImageSrc = window.URL.createObjectURL(files[0])

            setTimeout( () => {
                let dim = this.calculateAspectRatioFit(this.$refs.imgSrc.width, this.$refs.imgSrc.height, 720, 1080)

                const resizedCanvas  = document.createElement("canvas")
                resizedCanvas.height = dim.height
                resizedCanvas.width  = dim.width

                pica.resize(this.$refs.imgSrc, resizedCanvas).then(result => {
                    this.resizedImage  = result.toDataURL()
                    this.itemsCarousel.unshift( { src: this.resizedImage } )
                    pica.toBlob(result, 'image/jpeg', 0.85).then( (blob) => {
                        blob.name = files[0].name
                        this.objDocument.doc_name = files[0].name
                        this.objDocument.obj_file = blob
                    })
                    // Update states
                    this.resizeImage   = false
                    this.uploadedImage = true
                })
                .catch(error => {
                    console.log(error);
                });
            }, 500)

        },
        calculateAspectRatioFit(srcWidth, srcHeight, maxWidth, maxHeight) {
            var ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
            return { width: srcWidth * ratio, height: srcHeight * ratio };
        },
        saveDocument(){
            return new Promise( (resolve) => {
                if( this.objDocument.doc_id !== undefined && this.objDocument.doc_id > 0 && !this.objDocument.obj_file ){
                    this.putDocumentUpdate().then( () => {
                        this.$emit('update:docId', this.objDocument.doc_id)
                        resolve('resolved')
                    })
                } else {
                    this.postDocumentStore().then( () => {
                        this.$emit('update:docId', this.objDocument.doc_id)
                        resolve('resolved')
                    })
                }
            })
        },
        attachDocument(doc_id){
            return new Promise( (resolve, reject) => {
                this.$http.put( '/cards/' + this.objCard.cad_id + '/picture', { cad_doc_id_image: doc_id } ).then( () => {
                    this.toast({ color: 'success', text: 'Image attribuée' })
                    this.$emit('linkDoc', doc_id)
                    resolve('resolved')
                }).catch( (error) => { 
                    reject(error)
                })
            })
        },
        async deleteTree(){
            this.$root.$confirm('Supprimer', 'Confirmer la suppression de l\'arbre ?', { color: 'red', width: 500 }).then((confirm) => {
                if( confirm && this.objCard.cad_uid.length > 3 ){
                    this.$http.delete( '/trees/' + this.objCard.cad_uid ).then( () => {
                        this.toast({ color: 'success', top:false, bottom:true, right:false, left:true, text:'Arbre supprimée !' })
                        treeUpdateService.sendTree({ obj: { tre_uid: this.objCard.cad_uid } })
                        this.$router.push({ path: this.link.base.replace('/#', '') })
                    })
                }
            })
        },
        getCacheType       : cacheGetters.getTypes,
        getCacheState      : cacheGetters.getStates,
        getLists           : cacheGetters.getLists,
        getListsData       : cacheGetters.getListsData,
        getListsDataByLstId: cacheGetters.getListsDataByLstId,
    }
}
</script>